import './App.css';
import Header from './components/Header';
import Body from './components/Body';

function App() 
{
  return (
    <div>
      <Header/>
      <Body/>
    </div>
  );
}

export default App;
